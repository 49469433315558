@import url(https://fonts.googleapis.com/css2?family=Martel:wght@900&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

html,
body {
    margin: 0;
    height: 100%;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

#root {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100vh;
    width: 100vw;
}

*,
*::after,
*::before {
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-drag: none;
    -webkit-app-region: no-drag;
    cursor: default;
}

.nodePreview {
    -webkit-user-select: all;
    -ms-user-select: all;
        user-select: all;
    -webkit-user-drag: element;
    -webkit-app-region: no-drag;
    cursor: move;
}

input {
    -webkit-user-select: all;
    -ms-user-select: all;
        user-select: all;
}

::-webkit-scrollbar {
    width: 15px;
    height: 15px;
}

::-webkit-scrollbar-thumb {
    background: rgb(212, 85, 0);

    border-radius: 10px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(220, 119, 51);
}

::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 7px 10px 12px #f0f0f0;
}



